import {BrowserModule, TransferState} from '@angular/platform-browser';
import {APP_INITIALIZER, Inject, makeStateKey, NgModule, PLATFORM_ID} from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { FooterComponent } from './common/footer/footer.component';
import { HeaderComponent } from './common/header/header.component';
import { NgxFrfUtilitiesModule } from '@wame/ngx-frf-utilities';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NavComponent } from './common/nav/nav.component';
import { MenubarComponent } from './common/menubar/menubar.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {environment} from '../environments/environment';
import {ErrorInterceptor} from './core/error.interceptor';
import {DynamicDialogActionsComponent} from './common/dynamic-dialog-actions/dynamic-dialog-actions.component';
import {SharedCommonModule} from './common/shared.module';
import {AppRoutingModule} from './app-routing.module';
import {UserGroupsModule} from './user-groups/user-groups.module';
import {AdministrationModule} from './administration/administration.module';
import {ViewMyInfoComponent} from './user-info/view-my-info/view-my-info.component';
import {TableModule} from 'primeng/table';
import {ButtonModule} from 'primeng/button';
import {TokenInterceptor} from './core/token.interceptor';
import {FieldsetModule} from 'primeng/fieldset';
import {InputSwitchModule} from 'primeng/inputswitch';
import {LayoutComponent} from "./common/layout/layout.component";
import {ChangeMyProfileComponent} from './user-info/change-my-profile/change-my-profile.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LanguageTranslationService } from './common/service/language.translation.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {UnAuthComponent} from "./common/un-auth-component/un-auth.component";
import {SpsUserManualComponent} from "./administration/help-manual/sps-user-manual/sps-user-manual.component";
import {
	HelpOtherLanguagesComponent
} from "./administration/help-manual/help-other-languages/help-other-languages.component";

import {BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication} from '@azure/msal-browser';
import {MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalModule, MsalService,} from '@azure/msal-angular';
import { UserIdService } from './core/azure/services/user-id.service';
import {isPlatformBrowser} from "@angular/common";

export function MSALInstanceFactory(): IPublicClientApplication {
	return new PublicClientApplication({
	  auth: {
		clientId: environment.msalConfig.clientId,
		authority: environment.msalConfig.authority,
		redirectUri: environment.msalConfig_redirectUri
	  },
	  cache: {
		cacheLocation: BrowserCacheLocation.SessionStorage
	  },
	  system: {
		allowNativeBroker: false, // Disables WAM Broker
		loggerOptions: {
		  loggerCallback,
		  logLevel: LogLevel.Info,
		  piiLoggingEnabled: false
		}
	  }
	});
}

export function loggerCallback(logLevel: LogLevel, message: string) {
	console.log(message);
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();
    // protectedResourceMap.set(environment.apiConfig.spsApi.uri, environment.apiConfig.spsApi.scopes);
    // add additional scopes to protectedResourceMap here

    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap
    };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
	return {
	  interactionType: InteractionType.Redirect,
	  authRequest: {
		scopes: [...environment.apiConfig.spsApi.scopes]
	  },
	  loginFailedRoute: '/login-failed'
	};
  }
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function initializeApp(translationService: LanguageTranslationService) {
  return () => translationService.initializeTranslations('app');
}

const ENV_UPDATED_KEY = makeStateKey<any>('environmentUpdated');

@NgModule({
	declarations: [
		AppComponent,
		NavComponent,
		FooterComponent,
		HeaderComponent,
		MenubarComponent,
		DynamicDialogActionsComponent,
		ViewMyInfoComponent,
		ChangeMyProfileComponent,
		LayoutComponent,
		UnAuthComponent,
		SpsUserManualComponent,
		HelpOtherLanguagesComponent
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		RouterModule,
		FormsModule,
		NgxFrfUtilitiesModule,
		ReactiveFormsModule,
		HttpClientModule,
		SharedCommonModule,
		UserGroupsModule,
		AdministrationModule,
		TableModule,
		ButtonModule,
		FieldsetModule,
		InputSwitchModule,
		MsalModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		}),
	],
	providers: [
		UserIdService,
		{provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
		{provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
		{
			provide: APP_INITIALIZER,
			useFactory: initializeApp,
			deps: [LanguageTranslationService],
			multi: true
    	},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: MsalInterceptor,
			multi: true
		  },
		  {
			provide: MSAL_INSTANCE,
			useFactory: MSALInstanceFactory
		  },
		  {
			provide: MSAL_GUARD_CONFIG,
			useFactory: MSALGuardConfigFactory
		  },
		  {
			provide: MSAL_INTERCEPTOR_CONFIG,
			useFactory: MSALInterceptorConfigFactory
		  },
		  MsalService,
		  MsalGuard,
		  MsalBroadcastService
	],
	bootstrap: [AppComponent]
})
export class AppModule {

	constructor(
	@Inject(PLATFORM_ID) private platformId: string,
	private transferState: TransferState
) {
		if (isPlatformBrowser(this.platformId)) {
			const envConfig = this.transferState.get(ENV_UPDATED_KEY, null);
			const isLocalhost = window.location.origin.includes('localhost') ||
				window.location.origin.includes('127.0.0.1');

			if (envConfig) {
				environment.updateConfig(envConfig);
			}	else {
				environment.updateConfig({
					baseUrlGateway: window.location.origin + '/api/v1',
					msalConfig_redirectUri: window.location.origin + environment.msal_redirect_route
				});
			}

			if (isLocalhost) {
				environment.updateConfig({
					msalConfig_redirectUri: window.location.origin + environment.msal_redirect_route
				});
			}

			this.transferState.remove(ENV_UPDATED_KEY);

		}
}}
