import { Component, OnInit, ViewChild } from '@angular/core';
import {Subject, catchError, delay, finalize, of, switchMap, takeUntil, mergeMap} from 'rxjs';
import { SearchUserService } from '../../services/search-users/search-user.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { ResetRequest, Users } from '../users-interfaces';
import { Language } from '../language-interface';
import { EmailType } from '../email-type-interface';
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { AuditStatus } from '../audit-details';
import { DatePipe } from '@angular/common';
import { JobRole } from 'src/app/user-groups/create-new-user/user.model';
import { GroupInfo } from '../group-details';
import { CommonConstants } from "../../../common/common.constants";
import { TabView } from "primeng/tabview";
import {UserIdService} from "../../../core/azure/services/user-id.service";
import {tap} from "rxjs/operators";
import {UserRoleService} from "../../../common/services/user-role/user-role.service";
import {EnvConstants} from "../../../common/env.constants";

@Component({
  selector: 'edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss'],
  providers: [DatePipe]
})
export class EditUserComponent implements OnInit {
  @ViewChild(TabView) tabView: TabView;
  loading: boolean = false;
  loadingDialog: boolean = false;
  loadingGroup: boolean = false;
  editForm: FormGroup;
  oldStatus: string = '';
  selectedTab: string = 'Profile';
  language!: Language[];
  jobRolesList: JobRole[] = [];
  emailTypes: EmailType[] = [];
  activeIndex: number = 0;
  groupsList: GroupInfo[] = [];
  auditStatus: AuditStatus;
  hideGroup: boolean;
  disableInputs: boolean;
  loggedInUser:string;
  enableDialog: boolean = false;
  createdUserId: string = '';
  tempPwd: string = '';
  responseMessage: string = '';
  copied: boolean = false;
  copyUserId: boolean = false;
  copyPass: boolean = false;
  statusOptions: any[] = [{ label: 'Active', status: 'on' }, { label: 'Inactive', status: 'off' }];
  user: Users;
  multiselect:boolean;
  jobRoleRequired: boolean = false;
  forceUpdate: boolean = false;
  superAdmin:boolean =false;
  private destroy$ = new Subject<void>();
  constructor(private searchUserService: SearchUserService, private formBuilder: FormBuilder,
    private messageService: MessageService, public ref: DynamicDialogRef, public configDialog: DynamicDialogConfig,
  	private userIdService:UserIdService,private userRoleService: UserRoleService) {
  }
  ngOnInit() {
	  this.superAdmin = this.userRoleService.getUserRoleDetailsForSearchUser().isSuperAdmin();
    this.user = this.configDialog.data?.user;
    const mailHost = this.user?.mailHost ? this.user?.mailHost.toString().toUpperCase() == CommonConstants.TRUE : false;
    this.jobRolesList = this.configDialog.data?.profileData?.jobRoles;
    this.oldStatus = this.user?.fordStatus;
    this.emailTypes = this.configDialog.data?.profileData?.emailTypes ? this.configDialog.data?.profileData?.emailTypes : [];
    this.language = this.configDialog.data?.profileData?.languages ? this.configDialog.data?.profileData?.languages : [];
    this.auditStatus = this.configDialog.data?.profileData?.auditStatus ? this.configDialog.data?.profileData?.auditStatus : null;
    this.hideGroup = this.configDialog.data?.profileData?.hideGroup;
    this.disableInputs = this.configDialog.data?.profileData?.disableInputs;
    this.jobRoleRequired = this.configDialog.data?.profileData?.jobRoleRequired
    this.user?.emailType?.map((item: any) => {
      let index: number = this.emailTypes.findIndex((x: EmailType) => x.type === item);
      if (index !== -1) {
        this.emailTypes[index].selected = true;
      }
    });
	this.multiselect = ["fordeuropecode","fordasiapacific"].includes(this.user.fordSiteCodeType);
	  this.userIdService.getUserId().subscribe(user=>{
		  this.loggedInUser = user;
	  })
    this.editForm = this.formBuilder.group({
      name: [this.user.name],
      distinguishedName: [this.user?.distinguishedName],
      commonName: [this.user?.commonName],
      fordGID: [this.user?.fordGID],
      fordOtherEmail: [this.user?.fordOtherEmail],
      fordJobRole: [ this.user?.fordJobRole ? this.setSelectedJobRole(this.user?.fordJobRole.split(CommonConstants.SEMICOLONSEPARATOR)) : [], this.jobRoleRequired ? [Validators.required]:[]],
      msDSUserAccountDisabled: [this.user?.msDSUserAccountDisabled],
      emailType: [this.user?.emailType ],
      mailHost: [ mailHost ],
      new: [this.user?.new],
      market: [ this.user?.market],
      fordLastModifiedBy: [this.user?.fordLastModifiedBy],
      userId: [{ value: this.user?.userId, disabled: true }, Validators.required],
      firstName: [this.user?.givenName , [Validators.required, this.nameValidator,Validators.maxLength(31)]],//Make it mandatory after full edit api, Validators.required
      lastName: [ this.user?.sn , [Validators.required, this.nameValidator,Validators.maxLength(31)]],//Make it mandatory after full edit api, Validators.required
      language: [ this.user?.preferredLanguage ? this.setSelectedLanguage(this.user?.preferredLanguage) : {}, Validators.required],
      telephoneNumber: [ this.user?.telephoneNumber , [this.phoneFaxValidator]],
      fax:  [this.user?.facsimileTelephoneNumber , [this.phoneFaxValidator]],
      mail: [ this.user?.mail, [Validators.required, Validators.email,Validators.maxLength(256),Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]],
      postalAddress: [ this.user?.postalAddress , [this.mailingAddressValidator]],
      city: [ this.user?.city , [this.cityValidator]],
      state: [this.user?.state , [this.stateValidator]],
      country: [ this.user?.country, Validators.required], //Make it mandatory after full edit api, Validators.required
      postalCode: [ this.user?.postalCode , [this.postalCodeValidator]],
      fordSiteCode: [ this.user?.fordSiteCode, Validators.required], //Make it mandatory after full edit api, updatedValidators.required
      fordSiteCodeType: [ this.user?.fordSiteCodeType, Validators.required], //Make it mandatory after full edit api, Validators.required
      fmccBCode: [ this.user?.fmccbcode ],
      fmccBrand: [ this.user?.fmccbrand ],
      fmccClass: [this.user?.fmccclass ],
      fmccFinlab: [ this.user?.fmccfinLab],
      fmccLessor: [this.user?.fmcclessor ],
      fmccMkt: [this.user?.fmccmkt ],
      fmccRegion: [this.user?.fmccregion ],
      fmccSBCode: [this.user?.fmccsbcode ],
      status: [ this.user?.fordStatus,Validators.required]
    });
    if (this.disableInputs) this.editForm.disable();
    this.getGroupsForUser(this.user.userId);
  }

  setSelectedLanguage(language: string): Language {
    let selectedLanguage: any;
    let index: number = this.language.findIndex((x: any) => x?.languageCode?.trim() === language?.trim());
    if (index !== -1) {
      selectedLanguage = this.language[index];
    }
    return selectedLanguage;
  }

  setSelectedJobRole(jobRoles: string[]): JobRole[] | JobRole {
    let selectedJobRoles: JobRole[] = [];
    jobRoles.map((item: string) => {
      let index: number = this.jobRolesList.findIndex((x: any) => x.jobRoleCode?.trim() === item?.trim());
      if (index !== -1) {
        selectedJobRoles.push(this.jobRolesList[index]);
      }
    });
    return selectedJobRoles.length !=0 ? (this.multiselect ? selectedJobRoles : selectedJobRoles[0]) : null;
  }

  onChangeTab(event: any): void {
    this.selectedTab = this.tabView.tabs[event.index].header;
  }

  getGroupsForUser(userId: string): void {
    this.loadingGroup = true;
    this.searchUserService.getGroupsForUser(userId).pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data: any) => {
          this.loadingGroup = false;
          let groups: any[] = [];
          data.groupDetails.map((item: any) => {
            groups.push({ location: item.cn.split('-')[3], description: item.description ? item.description : '', primary: false });
          });
          groups.push({ location: data.primaryInfo.dn.split('=')[1].split(',')[0], description: data.primaryInfo.description ? data.primaryInfo.description : '', primary: true });
          this.groupsList = groups;
        },
        error: error => {
          this.loadingGroup = false;
          console.error(error);
        }
      });
  }

  closeDialog() {
    this.ref.close();
  }

  copyPassword(){
    this.copyPass=true;
  }
  copyUser(){
    this.copyUserId=true;
  }
  modelHide(){
		this.closeRef(true);
	}

  resetPassword(){
    this.loadingDialog=true;
    let resetRequest:ResetRequest = {
      userId: this.user?.userId,
      updatedBy: this.loggedInUser
    }
    return this.searchUserService.resetPassword(resetRequest).pipe(takeUntil(this.destroy$))
    .subscribe({
      next: (data: any) => {
        this.loadingDialog=false;
        this.enableDialog = true;
        this.createdUserId = data.userId;
        this.tempPwd = data.tempPwd;
      },
      error: error => {
        console.error(error);
        this.loadingDialog=false;
        this.enableDialog = true;
        this.responseMessage = error?.message;

      }
    });
  }
  getJobRolesOnSave(jobRoles: any[]): string {
    let jobRole: string = '';
    jobRoles.map((item: any) => {
      jobRole += item.jobRoleCode + CommonConstants.SEMICOLONSEPARATOR;
    });
    return jobRole;
  }
  onChangeEmailType(event, types = null): void {
    // let index: number = this.emailTypes.findIndex((x: EmailType) => x.type === types.type);
    // if(index !== -1)
    // this.emailTypes[index].selected = event.srcElement.checked;
  }

  saveUser(): void {
    /* TODO: lastModifiedBy to be modified with logged-in userId */
    if (this.editForm.valid) {
      this.loading = true;
	  const jobRole = this.multiselect ? this.getJobRolesOnSave(this.editForm.get('fordJobRole').value) : this.editForm.get('fordJobRole')?.value?.jobRoleCode;
      let user: Users = {
        "firstName": this.editForm.get('firstName').value,
        "lastName": this.editForm.get('lastName').value,
        "userId": this.editForm.get('userId').value,
        "email": this.editForm.get('mail').value,
        "emailType": this.editForm.get('emailType').value,
        "country": this.editForm.get('country').value,
        "city": this.editForm.get('city').value,
        "state": this.editForm.get('state').value,
        "postalCode": this.editForm.get('postalCode').value,
        "fordJobRoles": jobRole ? jobRole : null,
        "fordSiteCode": this.editForm.get('fordSiteCode').value,
        "siteCodeType": this.editForm.get('fordSiteCodeType').value,
        "postalAddress": this.editForm.get('postalAddress').value,
        "language": this.editForm.get('language').value.languageCode,
        "fax": this.editForm.get('fax').value,
        "fordStatus": this.editForm.get('status').value,
        "phone": this.editForm.get('telephoneNumber').value,
        "mailHost": this.editForm.get('mailHost').value.toString(),
        "lastModifiedBy": this.loggedInUser,
        "fmccFinLab": this.editForm.get('fmccFinlab').value,
        "fmccBCode": this.editForm.get('fmccBCode').value,
        "fmccBrand": this.editForm.get('fmccBrand').value,
        "fmccClass": this.editForm.get('fmccClass').value,
        "fmccLessor": this.editForm.get('fmccLessor').value,
        "fmccMkt": this.editForm.get('fmccMkt').value,
        "fmccRegion": this.editForm.get('fmccRegion').value,
        "fmccSBCode": this.editForm.get('fmccSBCode').value
      }
      this.searchUserService.updateUser(user).pipe(mergeMap((data: any) => {
	  	const currStatus = this.editForm.get('status').value;
        if (data.message.includes('Successfully updated User Profile for') && currStatus !== this.oldStatus) {
          return this.searchUserService.updateUserStatus({
            fordStatus: currStatus,
            lastModifiedBy: this.loggedInUser,
            userId: [this.editForm.get('userId').value],
		  	enableMarketAdminNotification:"false"
          }).pipe(tap((statusData: any) => {
			  this.oldStatus = currStatus;
			  if (currStatus === CommonConstants.ON) {
				  this.resetPassword();
			  }
		  }));
        }
        return of(data);
      }),
		tap((data: any) => this.forceUpdate=true),
        catchError((error) => {
          this.messageService.add({ severity: 'error', summary: error.message });
          return of(null);
        }),
        finalize(() => {
          this.loading = false;
        }),
        takeUntil(this.destroy$)
      ).subscribe((data: any) => {
        if (data) {
          if ([null, 'null', undefined, 'undefined', NaN, 'NaN', '', 200].indexOf(data.status) !== -1) {
            this.searchUserService.updateProfileStatus(true);
			  this.messageService.add({ severity: 'success', summary: 'Successfully updated User Profile for' + this.editForm.get('userId').value });
          } else {
            this.messageService.add({ severity: 'error', summary: data.message });
          }
        }
      });
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
	this.forceUpdate = false;
  }

  nameValidator(control: FormControl): { [key: string]: any } | null {
    const nameRegex = /^(?=.*[a-zA-Z0-9])[^<>&"()#%;+]{1,64}$/;
    const value = control.value;
    if (!value) {
      return null; // No value, no validation
    }
    const isValid = nameRegex.test(value);
    if (!isValid) {
      // Find illegal characters
      const illegalChars = value.match(/[\<>&"()#%;+]/g);
      if (illegalChars) {
        return { 'invalidName': { 'illegalChars': illegalChars.join(', ') } };
      }
    }
    // Check for alphanumeric characters
    if (!/[a-zA-Z0-9]/.test(value)) {
      return { 'missingAlphanumeric': true };
    }
    return null; // Valid
  }
  phoneFaxValidator(control: FormControl): { [key: string]: any } | null {
    const value = control.value;

    if (!value) {
      return null; // No value, no validation
    }

    const phoneFaxRegex = /^[A-Za-z0-9()\+ \/.#]{1,64}$/;
    const isValid = phoneFaxRegex.test(value);

    if (!isValid) {
      // Find illegal characters
      const illegalChars = value.match(/[^A-Za-z0-9()\+ \/.#]/g);
      if (illegalChars) {
        return { 'invalidPhoneFax': { 'message': `Please use only alphanumeric characters and these symbols: ()+ ./#` } };
      }
    }

    return null; // Valid
  }
  mailingAddressValidator(control: FormControl): { [key: string]: any } | null {
    const value = control.value;

    if (!value) {
      return null; // No value, no validation
    }

    const mailingAddressRegex = /^[^<>&"()#%;+]{1,1024}$/;
    const isValid = mailingAddressRegex.test(value);

    if (!isValid) {
      // Find illegal characters
      const illegalChars = value.match(/[\<>&"()#%;+]/g);
      if (illegalChars) {
        return { 'invalidMailingAddress': { 'message': `Please use only alphanumeric characters and spaces. These symbols are not allowed: <>&"()#%;+` } };
      }
    }

    return null; // Valid
  }
  cityValidator(control: FormControl): { [key: string]: any } | null {
    const value = control.value;

    if (!value) {
      return null; // No value, no validation
    }

    const cityRegex = /^[^<>&"()#%;+]{1,129}$/;
    const isValid = cityRegex.test(value);

    if (!isValid) {
      // Find illegal characters
      const illegalChars = value.match(/[\<>&"()#%;+]/g);
      if (illegalChars) {
        return { 'invalidCity': { 'message': `Please use only alphanumeric characters and spaces. These symbols are not allowed: <>&"()#%;+` } };
      }
    }

    return null; // Valid
  }
  stateValidator(control: FormControl): { [key: string]: any } | null {
    const value = control.value;

    if (!value) {
      return null; // No value, no validation
    }

    const stateRegex = /^[A-Za-z]{2}$/;
    const isValid = stateRegex.test(value);

    if (!isValid) {
      return { 'invalidState': { 'message': 'Please enter a 2-letter state abbreviation.' } };
    }

    return null; // Valid
  }
  postalCodeValidator(control: FormControl): { [key: string]: any } | null {
    const value = control.value;

    if (!value) {
      return null; // No value, no validation
    }

    const postalCodeRegex = /^[^<>&"()#%;+\-]{1,40}$/;
    const isValid = postalCodeRegex.test(value);

    if (!isValid) {
      // Find illegal characters
      const illegalChars = value.match(/[\<>&"()#%;+\-]/g);
      if (illegalChars) {
        return { 'invalidPostalCode': { 'message': `Please use only alphanumeric characters and spaces. These symbols are not allowed: <>&"()#%;+-` } };
      }
    }

    return null; // Valid
  }
  disableDialog(){
    this.enableDialog=false;
    this.tempPwd='';
    this.createdUserId='';
    this.copyUserId=false;
    this.copyPass=false;
   }

   closeRef(close:boolean=false){
    let data = {
      'close':close,
    }
    this.disableDialog();
    this.ref.close(data);
  }

    protected readonly EnvConstants = EnvConstants;
}
