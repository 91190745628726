<header id="app-header" class="bg-color-3 header-container">
	<app-nav></app-nav>
</header>
<div class="flex h-screen justify-content-center align-items-center">
	<div class="flex flex-column align-items-center">
		<h2>{{AUTH_MSG}}</h2>
		<i class="far fa-sad-tear fa-8x mt-2"></i>
		<p-button styleClass="mt-3" (click)="login()">Sign-in with other Options</p-button>
	</div>
</div>

