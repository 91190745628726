	import { Component,Input  } from '@angular/core';
import { AdministrationService } from '../../services/administration.service';
import { MessageService } from 'primeng/api';
import { Subject, takeUntil } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
	import {BranchModel} from "../../../common/models/branch.model";
	import {UserGroupsService} from "../../../user-groups/services/user-groups.service";
import { UserIdService } from 'src/app/core/azure/services/user-id.service';

@Component({
  selector: 'app-add-job-role',
  templateUrl: './add-job-role.component.html',
  styleUrls: ['./add-job-role.component.scss']
})
export class AddJobRoleComponent {
  createJobForm:FormGroup;
  loadingButton: boolean = false;
  loading: boolean = false;
  visible:boolean = false;
  loadingDialog: boolean = false;
  visibleResponseDialog:boolean = false;
  @Input() selectedBranch: any;
  @Input() selectedSiteCodeType: any;
  @Input() selectedMarket: any;
  jobRoleCode: string | undefined;
  jobRoleName: string | undefined;
  dialogTitle:string = 'jobRole';
  responseMessage: string = '';
  createdJobRole: boolean = false;
  loggedinUser: string = '';
  private unsubscribe = new Subject<void>();
  constructor(private adminService: AdministrationService,private userGroupService: UserGroupsService,private fb: FormBuilder, private authService: UserIdService) {
    this.adminService.getData().pipe(takeUntil(this.unsubscribe)).subscribe(data => {
      this.visible=data;
    });
    this.adminService.getBranch().pipe(takeUntil(this.unsubscribe)).subscribe(data => {
      this.createJobForm?.patchValue({
        selectedBranch: this.branchesList?.find((branch) => branch.branchCode == this.selectedBranch.branchCode)
      });
    });
    this.adminService.getSiteCodeType().pipe(takeUntil(this.unsubscribe)).subscribe(data => {
      this.createJobForm?.patchValue({
        selectedSiteCodeType: this.siteCodeTypeList.find((siteCodeType) => siteCodeType.siteCodeType === this.selectedSiteCodeType.siteCodeType)
      });
    });
    this.adminService.getMarket().pipe(takeUntil(this.unsubscribe)).subscribe(data => {
      this.createJobForm?.patchValue({
        selectedMarket: this.marketList.find((market) => market.marketName === data.marketName)
      });

    });
    this.adminService.getDialogTitle().pipe(takeUntil(this.unsubscribe)).subscribe(data => {
      this.dialogTitle="jobRole";
    });
	  this.userGroupService.fetchBranchList().subscribe((branchList:BranchModel[])=>{
		  this.branchesList = branchList;
	  })

  }
  ngOnInit(){
    this.authService.getUserId().pipe(takeUntil(this.unsubscribe)).subscribe((data:string) => {
      this.loggedinUser = data;
    });
    const branch = this.branchesList?.find((branch) => branch.branchCode == this.selectedBranch.branchCode);
    const siteCodeType = this.siteCodeTypeList.find((siteCodeType) => siteCodeType.siteCodeType === this.selectedSiteCodeType.siteCodeType);
    const market = this.marketList.find((market) => market.marketName === this.selectedMarket.marketName);
    this.createJobForm = this.fb.group({
      selectedBranch: new FormControl(branch, Validators.required),
      selectedSiteCodeType: new FormControl(siteCodeType, Validators.required),
      selectedMarket: new FormControl(market, Validators.required),
      jobRoleCode: new FormControl('', [Validators.required,Validators.pattern('^[a-zA-Z0-9\s].*$')]),
      jobRoleName: new FormControl('', [Validators.required,Validators.pattern('^[a-zA-Z0-9\s].*$')])
    });
  }
  ngOnDestroy(){
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
  branchesList: any[];

  @Input() siteCodeTypeList: any[] = []

  @Input() marketList: any[] = []

  public onChangeBranch(): void {
    this.loading=true;
    this.adminService.getSiteCodeTypes(this.createJobForm.value.selectedBranch).subscribe(data => {
      this.siteCodeTypeList = data.results.map((item: any) => {
        return {
          siteCodeType: item.value};
        })
        this.loading=false;
    });
  }

  public onChangeSiteCodeType(): void {
    this.loading=true;
    this.adminService.getMarkets(this.createJobForm.value.selectedBranch,this.createJobForm.value.selectedSiteCodeType).subscribe(data => {
      this.marketList = data.results.map((item: any) => {
        return {
          marketName: item.value};
        })
        this.marketList.unshift({marketName: 'ALL'});
        this.loading=false;
    });
  }

  onSubmit(){
    this.loadingButton=true;
    this.visibleResponseDialog=true;
    this.loadingDialog=true;
    let request={
      branch: this.createJobForm.value.selectedBranch?.branchCode,
      siteCodeType: this.createJobForm.value.selectedSiteCodeType?.siteCodeType,
      country: this.createJobForm.value.selectedMarket?.marketName,
      jobRoleCode: this.createJobForm.value.jobRoleCode,
      jobRoleName: this.createJobForm.value.jobRoleName,
      cdsId: this.loggedinUser,
      languageCode: 'en'
      // euroJobRoles: 'eu'
    }
    this.adminService.saveJobRole(request).subscribe({

      next: data => {
      this.loadingButton=false;
      this.loadingDialog=false;
      this.responseMessage=data.message;
      this.createdJobRole=true;
      // this.messageService.add({severity:'success', summary:'Job Role created successfully'});
      this.closeDialog();
      this.createJobForm.reset();
      // this.adminService.updateJobRefresh(true);
    },
     error: error => {
      this.loadingButton=false;
      this.loadingDialog=false;
      this.createdJobRole=false;
      this.responseMessage=error?.message;
      // this.messageService.add({severity:'error', summary:'Job Role creation failed'});
      }});
  }

  disableCreateButton(){
    return this.selectedBranch && this.selectedMarket && this.selectedSiteCodeType && this.jobRoleCode && this.jobRoleName ? false : true;
  }

  closeDialog(){
    //this.reset();
    this.visible = false;
    this.createJobForm.get('jobRoleCode').reset();
    this.createJobForm.get('jobRoleName').reset();
    this.adminService.updateData(this.visible);
  }
  closeResponseDialog(){
    this.visibleResponseDialog=false;
    this.createdJobRole? this.adminService.updateJobRefresh(true):"";
  }
}
