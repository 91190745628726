import { Component, Input } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { AdministrationService } from '../../services/administration.service';
import { ManageTemporaryIdService } from '../../services/manage.temporary.id.service';
import { Subject, takeUntil } from 'rxjs';
import { UserIdService } from 'src/app/core/azure/services/user-id.service';
import { CommonConstants } from 'src/app/common/common.constants';
interface JobRole {
  name: string
}

@Component({
  selector: 'create-temp-id',
  templateUrl: './create-temp-id.component.html',
  styleUrls: ['./create-temp-id.component.scss']
})
export class CreateTempIdComponent {
  loading: boolean = false;
  createTempForm:FormGroup;
  visible:boolean = false;
  enableDialog: boolean = false;
  createdUserId: string = '';
  tempPwd: string = '';
  responseMessage: string = '';
  copied: boolean = false;
  copyUserId: boolean = false;
  copyPass: boolean = false;
  @Input() selectedTempGroup: string;
  temporaryGroup: string = '';
  @Input() jobRoleList: any;
  loggedinUser: string = '';
  private onDestroy$ = new Subject<void>();
  private unsubscribe = new Subject<void>();
  constructor(private manageTempidService: ManageTemporaryIdService,private fb: FormBuilder,private authService:UserIdService) {
    this.manageTempidService.getOpenCreateTemporaryId().pipe(takeUntil(this.onDestroy$)).subscribe(data => {
      this.visible=data;
    });

    this.createTempForm = this.fb.group({
      firstName: new FormControl('', [Validators.required, this.nameValidator,Validators.maxLength(31)]),
      lastName: new FormControl('', [Validators.required, this.nameValidator,Validators.maxLength(31)]),
      email: new FormControl('', [Validators.required,Validators.email,Validators.maxLength(256),Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]),
      primaryLocation: new FormControl('', Validators.required),
      jobrole: new FormControl(''),
      comments: new FormControl('')
    });
	}

  jobrole!: JobRole[];


  ngOnInit() {
    this.authService.getUserId().pipe(takeUntil(this.unsubscribe)).subscribe((data:string) => {
      this.loggedinUser = data;
    });
  }

  onSubmit(){
    this.loading = true;
    const tempGroup = this.selectedTempGroup?.split('-')[1] || '';
    let request={
      groupName: tempGroup,
      firstName: this.createTempForm.value.firstName,
      lastName: this.createTempForm.value.lastName,
      email: this.createTempForm.value.email,
      primaryLocation: this.createTempForm.value.primaryLocation,
      fordJobRoles: this.createTempForm.value?.jobrole?.jobRoleName ? this.createTempForm.value?.jobrole?.jobRoleName : CommonConstants.NULL,
      comment: this.createTempForm.value.comments ? this.createTempForm.value.comments : CommonConstants.NULL,
      createdBy: this.loggedinUser
    }
    this.manageTempidService.createTempIdUserService(request).subscribe({
      next: data => {
       setTimeout(() => {
        this.loading=false;
        this.createdUserId=data.userId;
        this.tempPwd=data.tempPwd;
        this.enableDialog=true;
        this.visible=false;
        this.manageTempidService.createTemporaryUser(this.visible);
        this.createTempForm.reset();
      }, 45000);
      },
      error: error => {
        this.loading = false;
		this.enableDialog=true;
        this.responseMessage=error?.message;
      }
    });
  }

  nameValidator(control: FormControl): { [key: string]: any } | null {
    const nameRegex = /^(?=.*[a-zA-Z0-9])[^<>&"()#%;+]{1,64}$/;
    const value = control.value;
    if (!value) {
      return null; // No value, no validation
    }
    const isValid = nameRegex.test(value);
    if (!isValid) {
      // Find illegal characters
      const illegalChars = value.match(/[\<>&"()#%;+]/g);
      if (illegalChars) {
        return { 'invalidName': { 'illegalChars': illegalChars.join(', ') } };
      }
    }
    // Check for alphanumeric characters
    if (!/[a-zA-Z0-9]/.test(value)) {
      return { 'missingAlphanumeric': true };
    }
    return null; // Valid
  }

  copyPassword(){
    this.copyPass=true;
  }
  copyUser(){
    this.copyUserId=true;
  }

  closeDialog(){
    this.visible = false;
    this.manageTempidService.openCreateTemporaryId(this.visible);
    this.createTempForm.reset();
  }
  disableDialog(){
    this.enableDialog=false;
    this.tempPwd='';
    this.createdUserId='';
    this.copyUserId=false;
    this.copyPass=false;
    this.manageTempidService.updateTempTable(true);
   }
  ngOnDestroy(){
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}

