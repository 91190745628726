<div class="text-center h-5rem m-5" *ngIf="inlineLoader;else supportCenterFormBlock">
	<p-progressSpinner strokeWidth="5" styleClass="w-5rem h-5rem"
					   ariaLabel="{{'label.loading' | translate}}" animationDuration=".8s"/>
</div>
<ng-template #supportCenterFormBlock>
  <p-fieldset styleClass="mb-2 support-custom">
    <ng-template pTemplate="header">
      <div class="flex align-items-center text-100">
        <span class="pi pi-id-card mr-2 custom-icon"></span>
        <span class="font-bold text-lg" style="color: #00095B;">{{"header.contactInfo" | translate}}</span>
      </div>
    </ng-template>
    <div class="card">
      <form [formGroup]="supportCenterForm" (ngSubmit)="onSubmitSupportCenter()">
        <div class="flex flex-wrap mb-6">
          <div class="col-4">
            <div class="flex justify-content-start"><label for="alphabetic" class="font-bold block mb-2"> {{"label.email" | translate}}
              </label> </div>
            <input type="text" pInputText id="alphabetic" pKeyFilter="alpha" [readonly]="dealerCode"
              formControlName="email" class="w-full" />
          </div>
          <div class="col-4">
            <div class="flex justify-content-start"><label for="alphabetic" class="font-bold block mb-2"> {{"label.phone" | translate}}
              </label></div>
            <input pInputText id="alphabetic" pKeyFilter="alpha" [readonly]="dealerCode" formControlName="phone"
              class="w-full" />
          </div>
          <div class="col-4">
            <div class="flex justify-content-start"><label for="alphabetic" class="font-bold block mb-2"> {{"label.fax" | translate}}
              </label></div>
            <input pInputText id="alphabetic" pKeyFilter="alpha" [readonly]="dealerCode" formControlName="fax"
              class="w-full" />
          </div>
        </div>
        <div class="flex flex-wrap justify-content-center">
          <div class="col-2">
            <p-button icon="pi pi-check" *ngIf="!dealerCode" [disabled]="!supportCenterForm.valid" type="submit"
              label="{{'button.save' | translate}}" styleClass="p-button-text"></p-button>
          </div>
        </div>
      </form>
    </div>
  </p-fieldset>
</ng-template>


<p-dialog header="Header" [(visible)]="supportCenterModal" [modal]="true" [style]="{'width': '40vw','min-height':'35vh'}" class="dialoghw"
  (onHide)="closeSupportCenterDialog()" [closable]="true">
  <ng-template pTemplate="header">
    <span class="text-xl font-bold">{{"label.response" | translate}}</span>
  </ng-template>
  <div class="flex align-items-center justify-content-center" *ngIf="loading">
    <p-progressSpinner></p-progressSpinner>
  </div>
  <p *ngIf="!loading" class="flex m-6 justify-content-center text-center">
    {{backendApiResponse}}
  </p>
  <ng-template pTemplate="footer">
    <p-button icon="pi pi-times" label="{{'button.close' | translate}}" (click)="closeSupportCenterDialog()"
      styleClass="p-button-text"></p-button>
  </ng-template>
</p-dialog>
