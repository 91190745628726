import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { SearchUserService } from '../../services/search-users/search-user.service';
import {finalize, forkJoin, Subject, takeUntil} from 'rxjs';
import { Users } from '../users-interfaces';
import { EditUserComponent } from "../edit-user/edit-user.component";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { UserGroupsService } from 'src/app/user-groups/services/user-groups.service';
import { JobRole } from 'src/app/user-groups/create-new-user/user.model';
import { EmailType } from '../email-type-interface';
import { Language } from '../language-interface';
import { TranslateService } from '@ngx-translate/core';
import {PageType} from "../../../common/models/user-role/page-type.enum";
import { CommonConstants } from 'src/app/common/common.constants';

@Component({
  selector: 'search-users-table',
  templateUrl: './search-users-table.component.html',
  styleUrls: ['./search-users-table.component.scss']
})
export class SearchUsersTableComponent implements OnDestroy{
  @Input() users: Users;
	@Input() disableInputs;
  loading: boolean = false;
  private destroy$ = new Subject<void>();
  ref: DynamicDialogRef | undefined;
  jobroleData: JobRole[] = [];
  emailTypes: EmailType[] = [];
  language: Language[] = [];
  constructor(private searchUserService: SearchUserService, public dialogService: DialogService, private userGroupService: UserGroupsService,private translate:TranslateService) { }

  public onClickEdit(user: Users): void {
		let branch:string;
					if (user?.distinguishedName.indexOf(CommonConstants.OTHER)!==-1){
						branch = CommonConstants.OTHER;
					}else if (user?.distinguishedName.indexOf(CommonConstants.DEALER)!==-1){
						branch = CommonConstants.DEALER;
					}
	  let requestBody = {
			"branch": branch,
		  "siteCodeType": user?.fordSiteCodeType,
		  "marketName": user.country
	  }
	  let request: any = {
		  "siteCodeType": this.users?.fordSiteCodeType,
		  "country": this.users?.country,
		  "siteCode": this.users?.fordSiteCode
	  }
	  this.loading = true;
	  let regionMarket: string = user.fordSiteCodeType?.toString().trim() + '-' + user.country?.toString().trim();
	  let emailType = this.searchUserService.getEmailTypes(regionMarket);
	  let jobrole = this.searchUserService.getJobRoles(requestBody);
	  let language = this.userGroupService.getLanguageData();
	  let auditStatus =  this.searchUserService.getUserAuditStatus(user.userId);
	  forkJoin([emailType,jobrole,language,auditStatus])
		  .pipe(takeUntil(this.destroy$),finalize(()=>this.loading=false)).subscribe(res=>{
		  const [emailType, jobRole, language, auditStatus] = res;
		  let profileData: any = {
				disableInputs: this.disableInputs,
			  jobRoles: jobRole?.jobRoleResponseList ? this.userGroupService.mapJobRoles(jobRole.jobRoleResponseList) : [],
			  emailTypes: emailType?.emailTypeValue ? this.userGroupService.mapEmailType(emailType.emailTypeValue): null,
			  languages: language ? this.userGroupService.mapLanguages(language) : [],
		  }
		  if (auditStatus?.lastAudited || auditStatus?.lastAuditDate) {
			  profileData.auditStatus = {
				  lastAuditedBy: auditStatus?.lastAudited,
				  lastAuditedDate: auditStatus?.lastAuditDate
			  };
		  }

		  this.ref = this.dialogService.open(EditUserComponent,
			  {
				  header: this.translate.instant('header.editUser'), width: "100%",height: "92%",
				  data: { user: user, profileData: profileData },
			  });
	  });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

    protected readonly PageType = PageType;
}
