import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component, EventEmitter,
	Input, OnDestroy, OnInit, Output,
} from '@angular/core';
import { SortEvent } from 'primeng/api';
import {DatePipe} from "@angular/common";
import {EditUserComponent} from "../../administration/search-users/edit-user/edit-user.component";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {catchError, finalize, forkJoin, of, Subject, takeUntil} from "rxjs";
import {Users} from "../../administration/search-users/users-interfaces";
import {SearchUserService} from "../../administration/services/search-users/search-user.service";
import { TableRowSelectEvent, TableSelectAllChangeEvent } from 'primeng/table';
import {MemberTableModel,PasswordStatus} from "./member-user.model";
import {UserGroupsService} from "../services/user-groups.service";
import { TranslateService } from '@ngx-translate/core';
import {CommonConstants} from "../../common/common.constants";
import {PageType} from "../../common/models/user-role/page-type.enum";
import {PageAccessType} from "../../common/models/user-role/page-access-type.enum";
import {BaseUserGroupModel} from "../base-usergroup.model";
import {EnvConstants} from "../../common/env.constants";

@Component({
	selector: 'members-table',
	templateUrl: './members-table.component.html',
	styleUrls: ['./members-table.component.scss'],
	changeDetection:ChangeDetectionStrategy.OnPush,
	providers:[DatePipe]
})
export class MembersTableComponent implements OnInit,OnDestroy{
	userLoading:boolean;
	filteredTableRecords: any[] = [];
	showFilter: boolean = true;
	loadingArr:any[]=[];
	@Input({required:true}) siteCode: any;
	@Input({required:true}) baseUserGroupModel:BaseUserGroupModel;
	@Input({alias:"dataList", required:true}) customers!:MemberTableModel[];
	@Input() overallAuditStatus: string;
	ref: DynamicDialogRef | undefined;
	private destroy$ = new Subject<void>();
	selectedAudits:MemberTableModel[] = [];
	@Output() editUser = new EventEmitter<MemberTableModel>();
	@Output() removeUser = new EventEmitter<{ selectedAudits: MemberTableModel[], titleTab: string }>();
	@Input({required:true}) title: string;
	selectedMembers:MemberTableModel[] = [];
	emailTypes:any[];
	constructor(private datePipe: DatePipe,private dialogService:DialogService,
				private searchUserService:SearchUserService,private cd:ChangeDetectorRef,
				private userGroupsService:UserGroupsService,
				private translate:TranslateService) { }

	public onFilter(event) {
		this.filteredTableRecords = event.filteredValue;
	}

	customSort(event: SortEvent) {
		event.data.sort((data1, data2) => {
			let value1 = data1[event.field];
			let value2 = data2[event.field];
			let result = null;

			if (value1 == null && value2 != null) result = -1;
			else if (value1 != null && value2 == null) result = 1;
			else if (value1 == null && value2 == null) result = 0;
			else if (typeof value1 === 'string' && typeof value2 === 'string') result = value1.localeCompare(value2);
			else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;

			return event.order * result;
		});
	}
	// Function to handle row selection
  onRowSelect(event: TableRowSelectEvent,title:string) {
		this.selectedMembers=[];
      // Check if the row with the same userId is already in selectedAudits
			if (!this.selectedAudits.some(item => item.userId === event.data.userId)) {
				this.selectedAudits = [...this.selectedAudits, event.data];
			}


    this.removeUser.emit({selectedAudits: this.selectedAudits,titleTab:title});
  }

  // Function to handle row unselection
  onRowUnselect(event: Event,title:string) {
		this.selectedMembers=[];
    // Find the unselected row using the original event target
    const unselectedRow = this.findUnselectedRow(event.target);
    if (unselectedRow) {
      // Remove the row from selectedAudits
      const index = this.selectedAudits.findIndex(item => item.userId === unselectedRow.userId);
      if (index !== -1) {
        this.selectedAudits.splice(index, 1);
      }
    }

    // Emit the updated selectedAudits to the parent component
    this.removeUser.emit({selectedAudits: this.selectedAudits,titleTab:title});
  }
	// Helper function to find the unselected row (modified for string userId)
  private findUnselectedRow(target: EventTarget): any {
    let currentElement = target as HTMLElement;
    while (currentElement) {
      if (currentElement.tagName === 'TR' && currentElement.dataset.pSelectableRow) {
        const userId = currentElement.dataset.pSelectableRow; // Get userId as a string
        return this.customers.find(item => item.userId === userId);
      }
      currentElement = currentElement.parentElement as HTMLElement;
    }
    return null;
  }

	onTableSelectAll(event: TableSelectAllChangeEvent,title:string) {
    this.selectedAudits = event.checked ? [...this.customers] : [];
    this.removeUser.emit({selectedAudits: this.selectedAudits,titleTab:title});
  }


  /*TODO: Need to resure from edit user for getting dropdown value*/

	callEditUser(memberModel:MemberTableModel): void {
		this.userLoading = true;
		this.loadingArr[memberModel.userId] = true;

		this.searchUserService.searchUsers(memberModel.userId).pipe(
			takeUntil(this.destroy$))
			.subscribe({
				next: (user:Users)	 => {
					let branch:string;
					if (user?.distinguishedName.indexOf(CommonConstants.OTHER)!==-1){
						branch = CommonConstants.OTHER;
					}else if (user?.distinguishedName.indexOf(CommonConstants.DEALER)!==-1){
						branch = CommonConstants.DEALER;
					}
					let requestBody = {
						"branch": branch,
						"siteCodeType": user.fordSiteCodeType,
						"marketName": user.country
					};
					let request: any = {
						"siteCodeType": user.fordSiteCodeType,
						"country": user.country,
						"siteCode": user.fordSiteCode
					}
					let regionMarket: string = user.fordSiteCodeType.toString().trim() + '-' + user.country.toString().trim();
					let emailType = this.searchUserService.getEmailTypes(regionMarket);
					let jobrole = this.searchUserService.getJobRoles(requestBody);
					let language = this.userGroupsService.getLanguageData();
					let auditStatus =  this.searchUserService.getUserAuditStatus(user.userId);
					forkJoin([emailType, jobrole, language,auditStatus])
						.pipe(
							finalize(()=>{
								this.userLoading = false;
								this.loadingArr[memberModel.userId] = false;
								this.cd.markForCheck()}),
						).
					subscribe({
						next: res => {
							const [emailType, jobRole, language, auditStatus] = res;
							let profileData: any = {
								jobRoles: jobRole?.jobRoleResponseList ? this.userGroupsService.mapJobRoles(jobRole.jobRoleResponseList) : [],
								emailTypes: emailType?.emailTypeValue ? this.userGroupsService.mapEmailType(emailType.emailTypeValue): null,
								languages: language ? this.userGroupsService.mapLanguages(language) : [],
								hideGroup:true,
								disableInputs: memberModel.location != CommonConstants.PRIMARY,
								jobRoleRequired: jobRole?.jobRoleResponseList?.length > 0,
							}
							if (auditStatus?.lastAudited || auditStatus?.lastAuditDate) {
								profileData.auditStatus = {
									lastAuditedBy: auditStatus?.lastAudited,
									lastAuditedDate: auditStatus?.lastAuditDate
								};
							}
							this.ref = this.dialogService.open(EditUserComponent,
								{
									header: this.translate.instant('header.editUser'),width: "100%",height: "92%",
									data:{user,profileData}});
							this.ref.onClose.subscribe((data) => {
								if(data?.close) {
									this.editUser.emit(memberModel);
								}
							})
						}

					});

				}, error: error => {
					console.error('Error:', error);
				}
			});
	}

	getPasswordStatus(customer:MemberTableModel){
		return customer.passwordSetStatus === PasswordStatus.ACTIVE ? customer.passwordSetStatus+ ' until '+ this.datePipe.transform(customer.passwordDate,"dd-MMM-yy") : customer.passwordDate? customer.passwordSetStatus+ ' on '+ this.datePipe.transform(customer.passwordDate,"dd-MMM-yy"): customer.passwordSetStatus
	}

	protected readonly PasswordStatus = PasswordStatus;

	protected readonly CommonConstants = CommonConstants;

	ngOnDestroy(): void {
		this.loadingArr = [];
		this.destroy$.next();
		this.destroy$.complete();
	}

	ngOnInit(): void {
		console.log('member table on init')
	}

	protected readonly PageType = PageType;
	protected readonly PageAccessType = PageAccessType;
    protected readonly EnvConstants = EnvConstants;
}
