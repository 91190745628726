import {Injectable} from '@angular/core';
import {UserIdService} from './user-id.service';
import {UserRoleService} from "../../../common/services/user-role/user-role.service";
import {ActivatedRoute, ActivatedRouteSnapshot, Router} from "@angular/router";
import {AppRoutes} from "../../../app-router.contants";
import { LanguageTranslationService } from 'src/app/common/service/language.translation.service';
import { MsalService } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';
import {BehaviorSubject} from "rxjs";
import {SpinnerState} from "../../../common/models/spinner-state.model";
import {SpinnerService} from "../../../common/services/spinner.service";
/** This service handles Oauth2 implicit grant flow authentication using the configurations provided by the application and also interacts
 *  with sessionStorage stored parameters related to authentication.
 */
@Injectable({
	providedIn: 'root'
})
export class AuthService {
	/** the configured URL endpoint to send the user's browser to for token negotiation */
	private readonly loginEndpoint: string;

	/** the endpoint to send the user's browser to for logout, including redirect */
	private readonly logoutEndpoint: string;

	/** reference to the window object */
	private window: Window;

	/** Creates a new instance of the LoginComponent, makes available
	 * a DOCUMENT reference and instantiates required variables
	 *
	 * @param document angular provided reference to the current document
	 * @param config config object
	 * @param idService user id service for tracking currently logged-in user
	 */

	private ERROR_MSG = "Something went wrong. Please try again later.";

	constructor(
		private idService: UserIdService,
		private userRoleService:UserRoleService,
		private router:Router,
		private languageTranslationService:LanguageTranslationService,
		private msalService:MsalService,
		private spinnerService:SpinnerService
	) {}

	/** once the component is up and running, redirect the user to the auth provider */
	login(): void {
		sessionStorage.clear();
		(async () => {
			try {
				await this.msalService.loginRedirect({
					scopes: [...environment.apiConfig.spsApi.scopes],
					redirectUri: environment.msalConfig_redirectUri
				});
				this.router.navigateByUrl('/');
			} catch (error) {
				console.error('Login redirect failed:', error);
			}
		})();
	}

	/** clears browser session storage, unsets user ID and redirects to ADFS logout
	 */
	public logout(): void {
		this.clearAuthSession();
		this.msalService.logoutRedirect();
	}

	/** Function used to determine if the current token is valid. Checks token expiration against the current timestamp
	 * @returns whether or not the token is expired
	 */
	public isTokenExpired(): boolean {
		const epoch = Math.trunc(new Date().getTime() / 1000);
		let expEpoch = null;
		const tokenExp = sessionStorage.getItem('tokenExp');
		if (tokenExp && tokenExp !== 'null') {
			expEpoch = parseInt(tokenExp, 10);
			return epoch >= expEpoch;
		} else {
			return true;
		}
	}

	/** Uses isTokenExpired() to determine if the user credentials should be cleared and the user forwarded to the login component
	 * @returns for whether the user is "logged in" or not
	 */
	public async checkLogin(route:ActivatedRouteSnapshot=null): Promise<boolean> {
		try {
			await this.userRoleService.ensureInitialized();
			await this.languageTranslationService.initializeTranslations('auth');
			const pageType = route.data['pageType'];
			const hasRole:boolean =  this.userRoleService.checkViewAccess(pageType);
			if(!hasRole){
				const defaultRoute=this.userRoleService.getDefaultPageAccess() || AppRoutes.UNAUTHORIZED;
				return this.router.navigate([defaultRoute]);
			}
			return true;
		} catch (error) {
			this.spinnerService.setInitialLoadSpinner({isLoading:false,errorMessage:error?.message || this.ERROR_MSG});
			console.error('Error initializing user role:', error);
			return false;
		}
	}

	/** clears all sessionStorage related to authentication
	 */
	public clearAuthSession(): void {
		sessionStorage.removeItem('tokenExp');
		sessionStorage.removeItem('encodedAccessToken');
		sessionStorage.removeItem('userId');
		sessionStorage.removeItem('displayName');
	}

	private getUserName(){
		let dispName = sessionStorage.getItem('displayName');
		if(dispName) return dispName;
		const token = sessionStorage.getItem('encodedAccessToken');
		if(!token) return dispName;
		const decodedToken = JSON.parse(atob(token.split('.')[1]));
		const fordName = decodedToken?.name + ' ('+decodedToken?.upn?.split('@')[0]+')';
		dispName = decodedToken?.name ? fordName : decodedToken?.upn?.split('@')[0];
		sessionStorage.setItem('displayName',dispName);
		return dispName;
	}

	public setLoggedInUserName(){
		const userName = this.getUserName();
		this.idService.setUserName(userName);
	}


}
