import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {BehaviorSubject, finalize, Subject, takeUntil} from 'rxjs';
import { SupportCenterService } from './service/support-center-service';
import {SupportCenter, SupportCenterData} from "./support-center.model";

@Component({
  selector: 'support-center',
  templateUrl: './support-center.component.html',
  styleUrls: ['./support-center.component.scss']
})
export class SupportCenterComponent {

  supportCenterForm: FormGroup;
  supportCenterModal: boolean = false;
  loading: boolean = false;
  private destroy$ = new Subject<void>();
  backendApiResponse: string;
  inlineLoader:boolean = false;
  siteCodeType: string;
  marketCode: string;
  dealerCode: boolean;

  constructor(private fb: FormBuilder, private supportCenterService: SupportCenterService,private changeDetectorRef: ChangeDetectorRef,) {
    this.supportCenterForm = this.fb.group({
      email: [''],
      phone: [''],
      fax: [''],
    });
	  this.supportCenterService.getSupportCenterData()
		  .pipe(takeUntil(this.destroy$))
		  .subscribe((supportCenterData: SupportCenterData) => {
		  this.siteCodeType = supportCenterData?.siteCodeType;
		  this.marketCode = supportCenterData?.marketCode;
		  this.dealerCode = supportCenterData?.dealerCode;
		  console.log('Support Center Component Initialized');
		  this.getSupportCenterDetails();
	  });
  }

  onSubmitSupportCenter() {
    this.supportCenterModal = true;
    this.loading = true;
    let supportCenter: SupportCenter = this.supportCenterForm.value;
    supportCenter.siteCodeType = this.siteCodeType;
    supportCenter.marketCode = this.marketCode;
    this.supportCenterService.updateSupportCenter(supportCenter).pipe(takeUntil(this.destroy$))
      .subscribe({
        next: data => {
          this.backendApiResponse = data.message;
          this.loading = false;
          this.changeDetectorRef.markForCheck();
          this.getSupportCenterDetails();

        }, error: error => {
          this.loading = false;
          this.changeDetectorRef.markForCheck();
          this.backendApiResponse = 'The backend API have been failed to update support center details.';
          this.getSupportCenterDetails();
        }
      });
  }

  getSupportCenterDetails() {
    this.resetForm();
	this.inlineLoader = true;
    this.supportCenterService.getSupportCenterDetails(this.siteCodeType, this.marketCode)
		.pipe(takeUntil(this.destroy$),finalize(()=>this.inlineLoader = false))
      .subscribe({
        next: data => {
          if (data) {
            this.supportCenterForm.patchValue({
              siteCodeType: data.siteCodeType,
              marketCode: data.marketCode,
              supportCenterCode: data.supportCenterCode,
              email: data.email,
              phone: data.phone,
              fax: data.fax,
            });
          }
        }, error: error => {
        }
      });
  }

  closeSupportCenterDialog() {
    this.loading = false;
    this.supportCenterModal = false;
  }

  resetForm(): void {
    this.supportCenterForm.reset();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
