import {Component, OnInit} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {MenubarService} from '../menubar/service/menubar.service';
import {Router} from '@angular/router';
import {ReplaySubject} from 'rxjs';
import {environment} from '../../../environments/environment';
import {PageType} from "../models/user-role/page-type.enum";
import {UserIdService} from "../../core/azure/services/user-id.service";

@Component({
	selector: 'app-menubar',
	templateUrl: './menubar.component.html',
	styleUrls: ['./menubar.component.scss']
})
export class MenubarComponent implements OnInit {
	items: MenuItem[] | undefined;
	isCollapsed = false;
	pageTitle = '';
	userId: string | undefined;
	constructor(
		private menubarService: MenubarService,
		private userIdService: UserIdService,
		private router: Router
	) {
		this.menubarService.isCollapsed$.subscribe((isCollapsed) => {
			this.isCollapsed = isCollapsed;
		});

		this.userIdService.getUserId().subscribe((userId) => {
			this.userId = userId;
		});
	}

	ngOnInit() {
		this.items = [
			{
				label: 'title.myInfo',
				items: [
					{
						label: 'title.viewMyInfo',
						routerLink: '/view-my-info',
						icon: 'pi pi-info-circle',
						pageType:PageType.VIEW_MY_INFO
					},
					{
						label: 'title.changeMyProfile',
						routerLink: '/change-my-profile',
						icon: 'pi pi-cog',
						pageType:PageType.CHANGE_MY_PROFILE
					},
					{
						label: 'title.changeMyPassword',
						routerLink: '/change-my-password',
						icon: 'pi pi-cog',
						pageType:PageType.CHANGE_MY_PASSWORD
					}
				]
			},
			{
				label: 'User Groups',
				items: [
					{
						label: 'title.manageUserGroups',
						routerLink: '/manage-user-groups',
						// icon: 'pi pi-cog',
						pageType:PageType.TREE_STRUCTURE
					}
				],
				noAccordion: true
			},
			{
				label: 'Access',
				items: [
					{
						label: 'title.manageUserAccess',
						routerLink: '/manage-users-access',
						// icon: 'pi pi-cog',
						pageType: PageType.ACCESS_RIGHTS
					}
				],
				noAccordion: true
			},
			{
				label: 'title.administration',
				items: [
					{
						label: 'title.searchUsers',
						routerLink: '/search-users',
						icon: 'pi pi-search',
						pageType:PageType.SEARCH_USER
					},
					{
						label: 'title.manageTemporaryIds',
						routerLink: '/manage-temporary-ids',
						icon: 'pi pi-cog',
						pageType:PageType.MANAGE_TEMP_IDS
					},
					{
						label: 'title.createTemporaryGroup',
						routerLink: '/create-temporary-group',
						icon: 'pi pi-plus-circle',
						pageType:PageType.CREATE_TEMP_ID
					},
					{
						label: 'title.manageJobRoles',
						routerLink: '/manage-job-roles',
						icon: 'pi pi-cog',
						pageType:PageType.MANAGE_JOB_ROLES
					},
					{
						label: 'title.manageAuditConfiguration',
						routerLink: '/manage-audit-configuration',
						icon: 'pi pi-cog',
						pageType:PageType.MANAGE_AUDIT_CONFIG
					},
					{
						label: 'title.siteRecoveryTool',
						routerLink: '/site-recovery-tool',
						icon: 'pi pi-history',
						pageType:PageType.SITE_RECOVERY_TOOL
					},
					{
						label: 'title.retireePassword',
						routerLink: '/EUSR',
						icon: 'pi pi-cog',
						pageType:PageType.RETIREE_PASSWORD
					},
				]
			},
			{
				label: 'title.report',
				items: [
					{
						label: 'title.adminReport',
						routerLink: '/report/admin-report',
						icon: 'pi pi-cog',
						pageType:PageType.ADMIN_REPORT
					},
					{
						label: 'title.userReport',
						routerLink: '/report/user-report',
						icon: 'pi pi-cog',
						pageType:PageType.USER_REPORT
					},
					{
						label: 'title.auditReport',
						routerLink: '/report/audit-report',
						icon: 'pi pi-cog',
						pageType:PageType.GROUP_AUDIT_REPORT
					}
				]
			}
		];
	}

	toggleMenu() {
		this.menubarService.toggleMenu();
	}

	public redirectToPage(pageUrl: string, label: string): void {
		const externalLinks = {
			'/change-my-password': `${environment.changePasswordUrl}signin.aspx?cdsId=${this.userId}`,
			'/manage-users-access': environment.aga_url
		};

		if (externalLinks[pageUrl]) {
			window.open(externalLinks[pageUrl], '_blank');
		} else {
			this.menubarService.updateData(label);
			this.router.navigate([pageUrl]);
		}
	}

	protected readonly PageType = PageType;
}
