import {Component, inject, OnInit} from '@angular/core';
import {AuthService} from "../../core/azure/services/auth.service";
import {MessageService} from "primeng/api";
import {ActivatedRoute, Router} from "@angular/router";
import {CommonConstants} from "../common.constants";

@Component({
  selector: 'un-auth-component',
  templateUrl: './un-auth.component.html',
  styleUrls: ['./un-auth.component.scss']
})
export class UnAuthComponent implements OnInit{

	authService = inject(AuthService);
	messageService = inject(MessageService);
	AUTH_MSG:string;

	constructor(private route: ActivatedRoute) {
		this.route.queryParams.subscribe(params => {
			const code = params['code'];
			this.AUTH_MSG = CommonConstants.SESSION_EXPIRED === code ? "Your session has expired. Please log in again." : "You are not authorized to access the page";
		});
	}

	login(){
		this.authService.login()
	}

	ngOnInit(): void {
		this.messageService.add({severity:'error', summary:'Error', detail:`${this.AUTH_MSG} Please login to view this page.`});
	}
}
