import {Component, OnDestroy, OnInit} from '@angular/core';
import { AdministrationService } from '../../services/administration.service';
import { MessageService } from 'primeng/api';
import { Subject, takeUntil } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserIdService } from 'src/app/core/azure/services/user-id.service';
import {UserGroupsService} from "../../../user-groups/services/user-groups.service";
import {BranchModel} from "../../../common/models/branch.model";

@Component({
  selector: 'app-edit-job-role',
  templateUrl: './edit-job-role.component.html',
  styleUrls: ['./edit-job-role.component.scss']
})
export class EditJobRoleComponent implements OnDestroy,OnInit{
  editJobForm: FormGroup;
  loadingButton: boolean = false;
  loading: boolean = false;
  loadingDialog: boolean = false;
  visible:boolean = false;
  openModal:boolean = false;
  selectedBranch: any;
  selectedSiteCodeType: any;
  selectedMarket: any;
  jobRoleCode: string | undefined;
  jobRoleName: string | undefined;
  responseMessage: string = '';
  loggedinUser: string = '';
	branchesList: any[];
	siteCodeTypeList: any[] = []
	marketList: any[] = []
  private unsubscribe = new Subject<void>();
  constructor(private adminService: AdministrationService,private userGroupService:UserGroupsService,
			  private fb: FormBuilder,private authService: UserIdService) {
	this.userGroupService.fetchBranchList().subscribe((branchList:BranchModel[])=>{
		this.branchesList = branchList;
	});
	  this.adminService.getBranch().pipe(takeUntil(this.unsubscribe)).subscribe(data => {
		  this.selectedBranch=data;
	  });
    this.adminService.getSiteCodeType().pipe(takeUntil(this.unsubscribe)).subscribe(data => {

      this.selectedSiteCodeType=data;
    });
    this.adminService.getEditData().pipe(takeUntil(this.unsubscribe)).subscribe(data => {
      let market={
        marketName: data.country==="*" ? 'ALL' : data.country
      }
      this.selectedMarket=market;
      this.jobRoleCode=data.jobRoleCode;
      this.jobRoleName=data.jobRoleName;
      this.editJobForm?.patchValue({
        selectedBranch: this.selectedBranch,
        selectedSiteCodeType: this.selectedSiteCodeType,
        selectedMarket: this.selectedMarket,
        jobRoleCode: this.jobRoleCode,
        jobRoleName: this.jobRoleName
      });
      this.disableAndPatch('selectedBranch', this.selectedBranch);
      this.disableAndPatch('selectedSiteCodeType', this.selectedSiteCodeType);
      this.disableAndPatch('selectedMarket', this.selectedMarket);
      this.disableAndPatch('jobRoleCode', this.jobRoleCode);
    });
    this.adminService.getEditableModal().pipe(takeUntil(this.unsubscribe)).subscribe(data => {
      this.openModal=data;
    });

    this.onChangeBranch();
    this.onChangeSiteCodeType();
  }

  ngOnInit(){
    this.authService.getUserId().pipe(takeUntil(this.unsubscribe)).subscribe((data:string) => {
      this.loggedinUser = data;
    });
    this.editJobForm = this.fb.group({
      selectedBranch: new FormControl(this.selectedBranch, Validators.required),
      selectedSiteCodeType: new FormControl(this.selectedSiteCodeType, Validators.required),
      selectedMarket: new FormControl('', Validators.required),
      jobRoleCode: new FormControl('', Validators.required),
      jobRoleName: new FormControl('', [Validators.required,Validators.pattern("^[a-zA-Z0-9\s].*$")])
    });
  }
  disableAndPatch(controlName: string, value: any) {
    const control = this.editJobForm?.get(controlName);
    if (control) {
      control.enable();
      this.editJobForm.patchValue({ [controlName]: value });
      control.disable();
    }
  }
  ngOnDestroy(){
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public onChangeBranch(): void {
    this.loading=true;
    this.adminService.getSiteCodeTypes(this.selectedBranch).subscribe(data => {
      this.siteCodeTypeList = data.results.map((item: any) => {
        return {
          siteCodeType: item.value};
        })
        this.loading=false;
    });
  }

  public onChangeSiteCodeType(): void {
    this.loading=true;
    this.adminService.getMarkets(this.selectedBranch,this.selectedSiteCodeType).subscribe(data => {
      this.marketList = data.results.map((item: any) => {
        return {
          marketName: item.value};
        })
        this.marketList.unshift({marketName: 'ALL'});
        this.loading=false;
    });
  }

  onSubmit(){
    this.loadingButton=true;
    this.visible=true;
    this.loadingDialog=true;
    let request={
      branch: this.selectedBranch?.branchCode,
      siteCodeType: this.selectedSiteCodeType?.siteCodeType,
      country: this.selectedMarket?.marketName,
      jobRoleCode: this.jobRoleCode,
      jobRoleName: this.editJobForm.value.jobRoleName,
      cdsId: this.loggedinUser,
      languageCode: 'en'
      // euroJobRoles: 'eu'
    }
    this.adminService.updateJobRole(request).subscribe({
      next: data => {
      this.loadingButton=false;
      this.loadingDialog=false;
      this.responseMessage=data.message;
      // this.messageService.add({severity:'success', summary:'Job Role updated successfully'});
      this.closeDialog();
    },
     error: error => {
      this.responseMessage=error?.message;
      this.loadingDialog=false;
      // this.messageService.add({severity:'error', summary:'Job Role update failed - '+errorMessage});
      this.loadingButton=false;
      }});
  }
  disableUpdateButton(){
    return this.selectedBranch && this.selectedMarket && this.selectedSiteCodeType && this.jobRoleCode && this.jobRoleName ? false : true;
  }
  closeResponseDialog(){
    this.visible=false;
    this.adminService.updateJobRefresh(true);
  }
  closeDialog(){
    this.openModal = false;
    this.adminService.editModal(this.openModal);
  }
}
