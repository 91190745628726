export const CommonConstants = {
	PRIMARY:'Primary',
	TRUE:'TRUE',
	CREATE:'Create',
	UPDATE:'Update',
	DELETE:'Delete',
	CONFIRM_MSG:'message.proceedConfirmation',
	SITECODE:'SC',
	SITECODETYPE:'SCT',
	MARKET:'MARKET',
	MEMBERS:'Members',
	TEMPORARY:"Temporary",
	DEALERSHIP:"Dealership",
	OTHER:"Other",
	ON:"on",
	DEALER:"Dealer",
	SEMICOLONSEPARATOR:";",
	FLEET:"fleet",
	FORDSALESCODE:"fordSalesCode",
	NULL: null,
	SESSION_EXPIRED:"session"
};
