import {HttpClient} from '@angular/common/http';
import {Injectable, OnInit} from '@angular/core';
import {catchError, filter, firstValueFrom, from, map, Observable, of, switchMap, take, tap} from 'rxjs';
import {environment} from 'src/environments/environment';
import {ClientConstants} from '../../client.constants';
import {UserRole} from '../../models/user-role/user-role.model';
import {AccessLevel} from '../../models/user-role/access-level.enum';
import {PageType} from '../../models/user-role/page-type.enum';
import {PageAccessType} from "../../models/user-role/page-access-type.enum";
import {BaseUserGroupModel} from "../../../user-groups/base-usergroup.model";
import {CommonConstants} from "../../common.constants";
import {AppRoutes} from "../../../app-router.contants";
import { UserIdService } from 'src/app/core/azure/services/user-id.service';

@Injectable({
	providedIn: 'root'
})
export class UserRoleService implements OnInit {
	private initializationPromise: Promise<void> | null = null;
	userRole: UserRole | null = null;
	siteStructureBaseUrl: string = environment.baseUrlPcf + 'sitestructure/api/v1/';
	fdsBaseUrl: string = this.siteStructureBaseUrl + 'fds/';
	baseUrlGatewayMap: string = environment.baseUrlGateway+ '/gateway/for-map';
	baseUrlGatewayArray: string = environment.baseUrlGateway+ '/gateway/for-array';
	userId:string;
	constructor(private _httpClient: HttpClient,private idService:UserIdService) {
		this.idService.getUserId().subscribe((userId) => {
			this.userId = userId;
		});
	}

	ngOnInit(): void {
		console.log('User Role Service oninit');
	}

	public ensureInitialized(): Promise<void> {
		return this.initializeUserRole();
	}

	public getUserRoleDetailsForSearchUser(): UserRole{
		return this.userRole;
	}
	private initializeUserRole(): Promise<void> {
		if (!this.initializationPromise) {
			this.initializationPromise =  new Promise<void>((resolve, reject) => {
				this.getUserRoleDetails(this.userId)
				.pipe(
					take(1),
					tap((userRole: UserRole) => {
						this.userRole = userRole;
					})
				).subscribe({
					next: () => resolve(),
					error: (err) => {
						console.error('Error in initialization', err);
						reject(err);
        				},
				})
			}).then(() => {
					console.log('User role initialization complete');
				})
				.catch((error) => {
					this.initializationPromise = null;
					throw error;
				});
		}
		return this.initializationPromise;
	}

	public getUserRoleDetails(userId: string): Observable<UserRole> {
		return this.getUserRole(userId).pipe(
			map((data) => Object.assign(new UserRole(), data)),
			tap((userRole) => {
				this.userRole = userRole;
				this.userRole.fetchHighestRolesAndUsersDetails();
				console.log('User role:', userRole);
			})
		);
	}

	getUserRole(userId: string): Observable<UserRole> {
		console.log('getUserRole called');
		let requestBody: any = {
			"endpoint": `${this.fdsBaseUrl}user-role/${userId}`,
			"configName": ClientConstants.CLIENT_6
		}
		return this._httpClient.post(this.baseUrlGatewayMap, requestBody)
			.pipe(
				map((data) => {
					const userRole = Object.assign(new UserRole(), data);
					console.log('UserRole object:', userRole.userid);
					console.log('UserRole object:', userRole);
					console.log(
						'UserRole object superAdmin:',
						userRole.superAdmin
					);

					console.log(
						'status ',
						userRole.hasAccess(
							'Dealership',
							'fordasiapacific',
							'AUS',
							'11180',
							AccessLevel.MEMBER
						)
					);

					console.log(
						'status ....  ',
						userRole.getPageAccess(
							PageType.ADMIN_REPORT,
							'Dealership',
							'fordasiapacific',
							'AUS',
							'11180'
						)
					);
					return userRole;
				})
			);
	}

	getPageAccessType(pageTitle,branch=null, siteCodeType=null,market=null,siteCode=null):PageAccessType{
		return this.userRole.getPageAccess(
			pageTitle,
			branch,
			siteCodeType,
			market,
			siteCode
		);
	}

	getPageTileIfBaseModel(pageTitle:PageType,baseUserGroupModel:BaseUserGroupModel=null,skipPageAppend=false):PageType{
		console.log("Pagetitle",pageTitle)
		if (baseUserGroupModel && !skipPageAppend) {
			const { siteCode, market, siteCodeType } = baseUserGroupModel;
			if (siteCode) {
				pageTitle = PageType[`${CommonConstants.SITECODE}_${pageTitle}`];
			} else if (market) {
				pageTitle = PageType[`${CommonConstants.MARKET}_${pageTitle}`];
			} else if (siteCodeType) {
				pageTitle = PageType[`${CommonConstants.SITECODETYPE}_${pageTitle}`];
			}
		}
		return pageTitle;
	}

	checkViewAccess(pageType:PageType, baseUserGroupModel=null,skipPageAppend=false): boolean {
		if(!pageType) return true;
		const pageTitle: PageType = this.getPageTileIfBaseModel(pageType, baseUserGroupModel,skipPageAppend);

		if (!pageTitle) {
			console.log("Page title not found for item", pageType);
			return false; //TODO: Change this based on desired behavior when title is not found
		}
		const pageAccess = this.getPageAccessType(
			pageTitle,
			baseUserGroupModel?.branch,
			baseUserGroupModel?.siteCodeType,
			baseUserGroupModel?.market,
			baseUserGroupModel?.siteCode
		);
		console.log("Filter pipe", pageTitle, pageAccess);
		return pageAccess !== PageAccessType.NO_ACCESS;
	}

	getDefaultPageAccess():string{
		if(this.checkViewAccess(PageType.TREE_STRUCTURE)){
			return AppRoutes.MANAGE_USER_GROUPS
		}
		else if(this.checkViewAccess(PageType.MANAGE_TEMP_IDS)){
			return AppRoutes.MANAGE_TEMPORARY_IDS
		} else if(this.checkViewAccess(PageType.VIEW_MY_INFO))
			return AppRoutes.VIEW_MY_INFO
		else
			return null

	}
}
