import {Component, OnInit, ViewChild, OnDestroy} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {TabView} from 'primeng/tabview';
import {Subject} from 'rxjs';
import {finalize, takeUntil, tap} from 'rxjs/operators';
import {SearchUserService} from '../../administration/services/search-users/search-user.service';
import {MessageService} from 'primeng/api';
import {UserIdService} from '../../core/azure/services/user-id.service';
import {Users} from '../../administration/search-users/users-interfaces';
import {UserGroupsService} from '../../user-groups/services/user-groups.service';
import {Language} from '../../administration/search-users/language-interface';
import {
	nameValidator,
	mailingAddressValidator,
	cityValidator,
	stateValidator,
	postalValidator, telephoneNumberValidator, faxValidator
} from "../../core/validator";
import {CommonConstants} from "../../common/common.constants";
import { LanguageTranslationService } from 'src/app/common/service/language.translation.service';
import {ViewMyInfoConstants} from "../view-my-info/view-my-info.constants";
import {EnvConstants} from "../../common/env.constants";


@Component({
	selector: 'change-my-profile',
	templateUrl: './change-my-profile.component.html',
	styleUrls: ['./change-my-profile.component.scss']
})
export class ChangeMyProfileComponent implements OnInit, OnDestroy {
	@ViewChild(TabView) tabView: TabView;
	loading = false;
	loadingGroup = false;
	editForm: FormGroup;
	selectedTab = 'Profile';
	jobRolesList = [];
	emailTypes = [];
	language = [];
	groupsList = [];
	user: Users;
	infoLoader:boolean=false;
	private destroy$ = new Subject<void>();

	constructor(
		private searchUserService: SearchUserService,
		private formBuilder: FormBuilder,
		private messageService: MessageService,
		private userIdService: UserIdService,
		private userGroupsService: UserGroupsService,
		private languageTranslationService: LanguageTranslationService
	) {}

	ngOnInit() {
		this.userIdService.getUserId().subscribe((userId) => {
			this.infoLoader=true;
			this.searchUserService
				.searchUsers(userId).pipe(finalize(()=>this.infoLoader=false))
				.subscribe((userDetails) => {
					this.user = userDetails;
					if (!this.user) return;
					this.getGroupsForUser(this.user.userId);
				});
		});
	}

	initializeForm() {
		this.editForm = this.formBuilder.group({
			fordJobRole: [ViewMyInfoConstants.EMPTY_STRING],
			language: [
				this.user.preferredLanguage
					? this.setSelectedLanguage(this.user.preferredLanguage)
					: [],
				Validators.required
			],
			telephoneNumber: [this.user.telephoneNumber, [telephoneNumberValidator()]],
			fax: [this.user.facsimileTelephoneNumber, [faxValidator()]],
			mail: [
				this.user.mail,
				[Validators.required, Validators.email,Validators.maxLength(256),Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]
			],
			postalAddress: [
				this.user.postalAddress,
				[mailingAddressValidator()]
			],
			city: [this.user.city, [cityValidator()]],
			state: [this.user.state, [stateValidator()]],
			postalCode: [this.user.postalCode, [postalValidator()]],
			userId: [
				{value: this.user.userId, disabled: true},
				Validators.required
			],
			firstName: [
				{value: this.user.givenName, disabled: true},
				[Validators.required, nameValidator,Validators.maxLength(31)]
			],
			lastName: [
				{value: this.user.sn, disabled: true},
				[Validators.required, nameValidator,Validators.maxLength(31)]
			],
			country: [
				{value: this.user.country, disabled: true},
				Validators.required
			],
			fordSiteCode: [
				{value: this.user.fordSiteCode, disabled: true},
				Validators.required
			],
			fordSiteCodeType: [
				{value: this.user.fordSiteCodeType, disabled: true},
				Validators.required
			],
			mailHost: [{value: this.user.mailHost || false, disabled: true}],
			status: [
				{value: this.user.fordStatus, disabled: true},
				Validators.required
			],
			lastModifiedBy: [
				{value: sessionStorage.getItem('userId'), disabled: true}
			]
		});

		if (this.jobRolesList && this.jobRolesList.length > 0) {
			this.editForm.get('fordJobRole').setValidators(Validators.required);
		} else {
			this.editForm.get('fordJobRole').clearValidators();
		}
		this.editForm.get('fordJobRole').updateValueAndValidity();

		console.log('Initialized fordJobRole:', this.editForm.get('fordJobRole').value);

		const fordJobRole = this.user.fordJobRole
			? this.user.fordJobRole.split(CommonConstants.SEMICOLONSEPARATOR).map(role => role.trim()).filter(role => role)
			: [];
		this.editForm.get('fordJobRole').setValue(this.isMultiSelect() ? fordJobRole : fordJobRole[0] || '');

		this.editForm
			.get('fordJobRole')
			.valueChanges.subscribe((selectedRoles) => {
			this.updateSelectedJobRoles(selectedRoles);
		});
	}
	loadJobRoles(branch: string) {
		this.loading = true;
		const requestBody = {
			branch: branch,
			siteCodeType: this.user.fordSiteCodeType,
			marketName: this.user.country
		};

		return this.searchUserService.getJobRoles(requestBody).pipe(
			finalize(() => (this.loading = false)),
			tap((jobRole) => {
				this.jobRolesList = jobRole?.jobRoleResponseList
					? jobRole.jobRoleResponseList.map((role) => ({
						label: role.jobRoleName || 'Unknown Role',
						value: role.jobRoleCode || 'Unknown Code'
					}))
					: [];
			})
		);
	}

	updateSelectedJobRoles(selectedRoles: string | string[]) {
		if (!Array.isArray(selectedRoles)) {
			selectedRoles = [selectedRoles];
		}
		const updatedRoles = selectedRoles
			.map((role) => this.jobRolesList.find((x) => x.value === role))
			.filter(Boolean);
		this.editForm
			.get('fordJobRole')
			.setValue(updatedRoles, { emitEvent: false });
	}
	isMultiSelect(): boolean {
		const siteCodeType = this.editForm.get('fordSiteCodeType')?.value;
		return (
			siteCodeType === 'fordasiapacific' ||
			siteCodeType === 'fordeuropecode'
		);
	}

	loadLanguages() {
		this.loading = true;
		this.userGroupsService
			.getLanguageData()
			.pipe(
				takeUntil(this.destroy$),
				finalize(() => (this.loading = false))
			)
			.subscribe({
				next: (languages) => {
					this.language = languages;
					if (this.user && this.user.preferredLanguage) {
						const selectedLanguage = this.setSelectedLanguage(
							this.user.preferredLanguage
						);
						this.editForm.get('language').setValue(selectedLanguage);
					}
				},
				error: (error) => {
					console.error('Error loading languages', error);
					this.messageService.add({
						severity: 'error',
						summary: 'Error',
						detail: 'Failed to load languages'
					});
				}
			});
	}

	getGroupsForUser(userId: string): void {
		this.loadingGroup = true;
		let branch:string;
		this.searchUserService
			.getGroupsForUser(userId)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: (data: any) => {
					this.loadingGroup = false;
					const groups = data.groupDetails.map((item: any) => ({
						location: item.cn.split('-')[3],
						description: item.description || '',
						primary: false
					}));
					groups.push({
						location: data.primaryInfo.dn
							.split('=')[1]
							.split(',')[0],
						description: data.primaryInfo.description || '',
						primary: true
					});
					this.groupsList = groups;
					if (
						this.user?.distinguishedName.indexOf(
							CommonConstants.OTHER
						) !== -1
					) {
						branch = CommonConstants.OTHER;
					} else if (
						this.user?.distinguishedName.indexOf(
							CommonConstants.DEALER
						) !== -1
					) {
						branch = CommonConstants.DEALER;
					}
					this.loadJobRoles(branch).subscribe(() => {
						this.initializeForm();
						this.loadLanguages();
					});
				},
				error: (error) => {
					this.loadingGroup = false;
					console.error(error);
				}
			});
	}

	setSelectedLanguage(language: string): Language | null {
		const index = this.language.findIndex(
			(x) => x?.languageCode?.trim() === language?.trim()
		);
		if (index !== -1) {
			console.log('Selected language:', this.language[index]);
		} else {
			console.log('Language not found for code:', language);
		}
		return index !== -1 ? this.language[index] : null;
	}
	onLanguageChange(event: any): void {
		const selectedLanguageCode = event.value;
		const selectedLanguage = this.setSelectedLanguage(selectedLanguageCode);
		console.log('Language changed to:', selectedLanguage);
		this.editForm.get('language').setValue(selectedLanguage);
	}

	onChangeTab(event: any): void {
		this.selectedTab = this.tabView.tabs[event.index].header;
	}

	saveUser(): void {
		if (this.editForm.valid) {
			this.loading = true;
			let jobRoles = this.editForm.get('fordJobRole')?.value || [];
			if (!Array.isArray(jobRoles)) {
				jobRoles = [jobRoles];
			}
			const language = this.editForm.get('language')?.value?.languageCode || '';
			const user: Users = {
				userId: this.editForm.get('userId')?.value || '',
				firstName: this.editForm.get('firstName')?.value || '',
				lastName: this.editForm.get('lastName')?.value || '',
				email: this.editForm.get('mail')?.value || '',
				fordJobRoles: jobRoles.length > 0 ? this.getJobRolesOnSave(jobRoles) : null,
				language: language || null,
				phone: this.editForm.get('telephoneNumber')?.value || '',
				fax: this.editForm.get('fax')?.value || '',
				postalAddress: this.editForm.get('postalAddress')?.value || '',
				city: this.editForm.get('city')?.value || '',
				state: this.editForm.get('state')?.value || '',
				postalCode: this.editForm.get('postalCode')?.value || '',
				country: this.editForm.get('country')?.value || '',
				fordSiteCode: this.editForm.get('fordSiteCode')?.value || '',
				siteCodeType: this.editForm.get('fordSiteCodeType')?.value || '',
				emailType: this.editForm.get('emailType')?.value || [],
				fordStatus: this.editForm.get('status')?.value || '',
				lastModifiedBy: this.editForm.get('lastModifiedBy')?.value || '',
				mailHost: this.editForm.get('mailHost').value.toString()
			};

			this.searchUserService
				.updateUser(user)
				.pipe(finalize(() => (this.loading = false)))
				.subscribe({
					next: () => {
						this.messageService.add({
							severity: 'success',
							summary: 'Success',
							detail: 'User details updated successfully'
						});
						this.editForm.get('language').setValue(this.setSelectedLanguage(user.language));

					},
					error: (error) => {
						console.error('Error updating user:', error);
						this.messageService.add({
							severity: 'error',
							summary: 'Error',
							detail: 'Failed to update user details'
						});
					}
				});
		} else {
			this.messageService.add({
				severity: 'error',
				summary: 'Error',
				detail: 'Please fill in all required fields'
			});
		}
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}

	getJobRolesOnSave(jobRoles: any[]): string {
		return jobRoles.map((item) => item.value).join(CommonConstants.SEMICOLONSEPARATOR);
	}

	protected readonly Array = Array;
    protected readonly EnvConstants = EnvConstants;
}
