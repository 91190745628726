import { inject, Injectable } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { ActivatedRouteSnapshot, CanActivate, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { MsalGuard, MsalService } from '@azure/msal-angular';
import { firstValueFrom, Observable } from 'rxjs';
import { UserIdService } from '../services/user-id.service';

@Injectable({ providedIn: 'root' })
export class UserRoleGuard implements CanActivate {
  constructor(private msalGuard : MsalGuard, private authService: AuthService, private msalService: MsalService, private idService:UserIdService) {}

   canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): | Observable<boolean | UrlTree> | 
   Promise<boolean | UrlTree | boolean> | boolean | UrlTree{

    return firstValueFrom(this.msalGuard.canActivate(route, state)).then((msalResult) => {
      if (!msalResult) {
        return false;
      }
      console.log('user ID from session storage : ' + sessionStorage.getItem('userId'));
      if(!sessionStorage.getItem('userId')){
        return firstValueFrom(this.msalService.handleRedirectObservable()).then((response: any) => {
          console.log('inside the guard handleRedirectObservable');
          let userId = undefined;
          let accessToken = undefined;
          if(response && response.account){
            this.msalService.instance.setActiveAccount(response.account);
          }
          if (response) {
            accessToken = response.accessToken;
            const decodedToken = JSON.parse(atob(response.accessToken.split('.')[1]));
            userId = decodedToken?.upn?.split('@')[0];
            sessionStorage.setItem('userId', userId);
            sessionStorage.setItem('encodedAccessToken', accessToken);
            sessionStorage.setItem('tokenExp', decodedToken?.exp);
            console.log('userID and accessToken : '+ userId + ' : '+accessToken);
            this.idService.setUserId(userId);
          }
          return this.authService.checkLogin(route);
        });
      }
      return this.authService.checkLogin(route);
    });
  }
}
