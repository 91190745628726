import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom, Observable, of } from 'rxjs';
import { catchError, switchMap, take, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ClientConstants } from '../client.constants';
import { after } from 'node:test';
import { UserIdService } from 'src/app/core/azure/services/user-id.service';

@Injectable({
  providedIn: 'root',
})
export class LanguageTranslationService {

  userId: string | undefined;

  constructor(private _httpClient: HttpClient, private translate: TranslateService,private userIdService: UserIdService) {
    //this.translate.setDefaultLang('en');
    this.userIdService.getUserId().subscribe((userId) => {
      if (userId) {
        this.userId = userId;
        // You can trigger other actions here, like making an HTTP request
      } else {
        console.log('User ID is not yet available.');
      }
		});
  }


  fetchPreferredLanguage(userId: string): Observable<any> {

    if (!userId) {
      return of({ 'preferredLanguage': 'en' });
    }

    let requestBody: any = {
      'endpoint': environment.baseUrlPcf + 'usermanagement/api/v2/user/getUserDetails?userId=' + userId,
      'configName': ClientConstants.CLIENT_2
    }
    return this._httpClient.post(environment.baseUrlGateway + '/gateway/for-map', requestBody).pipe(
      catchError(() => of({ 'preferredLanguage': 'en' }))
    );
  }

  loadTranslations(language: string): Observable<any> {
    return this._httpClient.get<{ [key: string]: any }>(`/assets/i18n/${language}.json`).pipe(
      catchError(() => of({}))
    );
  }

  afterLoginCount:number = 0; 
  initializeTranslations(invokeFrom:string): Promise<void> {
    if (invokeFrom==='auth') {
      this.afterLoginCount+=1
    }
    
    if((invokeFrom==='auth' && this.afterLoginCount === 1) || (invokeFrom==='app' && (!this.userId || this.userId==='Not Logged In')) ||(invokeFrom==='profile')){
      if(invokeFrom==='app'){
      this.userId=null;
    }
      const supportedLanguages = ['fr-be', 'zh', 'cs', 'da', 'nl', 'en', 'en-US', 'fi', 'nl-be', 'fr', 'de', 'el', 'hu', 'it', 'ja', 'no', 'pl', 'pt', 'ro', 'ru', 'es', 'sv'];
      return new Promise((resolve, reject) => {
        this.fetchPreferredLanguage(this.userId).pipe(
          switchMap((userDetails) => {
            var language='';
            if (userDetails.preferredLanguage && supportedLanguages.includes(userDetails.preferredLanguage)) {
                  language=userDetails.preferredLanguage;
            }else{
              language='en';
            }
            return this.loadTranslations(language).pipe(
              tap((translations) => {
                  this.translate.setTranslation(language, translations, true);
                  this.translate.use(language);
              }),
              catchError((error) => {
                console.error('Error loading translations', error);
                return of(null);
              })
            );
          })
        ).subscribe({
          next: () => resolve(),
          error: (err) => {
            console.error('Error in initialization', err);
            reject(err);
          },
        });
      });
    }
    return lastValueFrom(of(null));
  }
}
